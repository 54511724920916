import React, { Component } from 'react';
import About from './About.component';

class AboutContainer extends Component {
    state = {  } 
    render() { 
        return (<About />);
    }
}
 
export default AboutContainer;