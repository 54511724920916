import React, { Component } from 'react';
import ContactUs from './ContactUs.component';

class ContactUsContainer extends Component {
    state = {  } 
    render() { 
        return (<ContactUs />);
    }
}
 
export default ContactUsContainer;