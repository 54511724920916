import React, { Component } from "react";
import InProgress from "../InProgress/InProgress.component";

class About extends Component {
  state = {};
  render() {
    return (
      <>
        <InProgress />
      </>
    );
  }
}

export default About;
