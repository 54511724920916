export const bannerData = {
  data: [
    {
      id: 1,
      url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708880079/Photo%20Man/Banner/qxslbyizlbypg3whaobg.jpg",
    },
    {
      id: 2,
      url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708880222/Photo%20Man/Banner/rgnpsozcod2fkvcbvspy.jpg",
    },
    {
      id: 3,
      url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708880357/Photo%20Man/Banner/umuk2cbdhl6zibohkdrh.jpg",
    },
    {
      id: 4,
      url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708880129/Photo%20Man/Banner/ylx0ckrbrkedymba6stb.jpg",
    },
    {
      id: 5,
      url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708880394/Photo%20Man/Banner/d1hcbmaqjiyividr0yxw.jpg",
    },
    {
      id: 6,
      url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708880245/Photo%20Man/Banner/ysxvzccsdvr0rvxslrgb.jpg",
    }
  ],
};
