export const mobileBannerData = {
    data: [

      {
        id: 1,
        url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708884022/Photo%20Man/Banner/Mobile%20banner/qby6j0gwl58ypawhfas0.jpg",
      },
      {
        id: 2,
        url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1709398581/Photo%20Man/Maternity/ROHINI_BOOPATHY%20-%20KAVA%20ISLAND/bdon4delq1z64dbhfazq.jpg",
      },
      {
        id: 3,
        url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708884115/Photo%20Man/Banner/Mobile%20banner/o2rk7f6qwyqwlqieoptf.jpg",
      },
      {
        id: 4,
        url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1709378689/Photo%20Man/Banner/Mobile%20banner/d1ytflou7xsuzvyi1k5x.jpg",
      },
      {
        id: 5,
        url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1709370113/Photo%20Man/Out%20door/Vikram/cgnl5mjwoanwajg2llh0.jpg",
      },
      {
        id: 6,
        url: "https://res.cloudinary.com/dnmr1sbs3/image/upload/v1708884339/Photo%20Man/Banner/Mobile%20banner/igktcmwg3gymzmsqxxfm.jpg",
      }
    ],
  };
  